import { Component, OnInit } from '@angular/core';

import { AppConstants } from 'src/app/core/shared';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public constructor() { }

  public ngOnInit(): void { }

  public readonly appName: string = AppConstants.AppName;
}