<nav id="navbar" class="bg-dark mb-5 navbar navbar-expand-lg navbar-dark">
    <div class="container">
      <a class="navbar-brand" routerLink="/">{{ appName }}</a>
      <!--
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarLinks"
        aria-controls="navbarLinks"
        aria-expanded="false"
        aria-label="Toggle Navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div id="navbarLinks" class="collapse navbar-collapse"></div>
      -->
    </div>
  </nav>